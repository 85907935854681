import React from 'react';
import './loaderBasic.css'

const LoaderBasic = () => {
    return (
        <>
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </>
    );
};

export default LoaderBasic;